<template>
  <div class="overflow" @click.self="close">
    <div class="w1">
      <div class="roomInformation">
        <flickity
          ref="mainSlider"
          class="previewMainSlider"
          :class="{ hiddenOpacity: !isImgsLoaded }"
          :options="sliderOptions"
        >
          <div
            class="slide"
            v-for="(slide, idx) in galleryData.list"
            :key="idx"
          >
            <img
              @load="loaded"
              :src="path(slide.image)"
              :alt="path(slide.image)"
            />
          </div>
        </flickity>
        <div class="navSliderWrapper" :class="{ hiddenOpacity: !isImgsLoaded }">
          <flickity
            ref="navSlider"
            class="previewNavSlider"
            :options="navSettings"
          >
            <div
              class="slide"
              v-for="(item, idx) in galleryData.list"
              :key="idx"
              @click="getSlideId(idx)"
            >
              <div class="imgWarpper">
                <img :src="path(item.image)" alt="" />
              </div>
            </div>
          </flickity>
          <button class="sliderBtn prevBtn" @click="previous()">
            <i class="ic-arrow-drop"></i>
          </button>
          <button class="sliderBtn nextBtn" @click="next()">
            <i class="ic-arrow-drop"></i>
          </button>
        </div>
        <p class="title">
          {{ roomInfo.translate.name }}
        </p>
        <p class="desc">{{ roomInfo.translate.description }}</p>
        <ul class="roomOption">
          <li
            class="roomAttr"
            v-for="(item, idx) in roomInfo.model.room_options"
            :key="idx"
          >
            <div class="liTitle">
              <i class="icon" :class="item.icon"></i>
              {{ item.name }}
            </div>
            <ul class="optionList">
              <li v-for="(option, idx) in item.options" :key="idx">
                <p>
                  {{ option.value }}
                </p>
                <span>{{ option.description }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Flickity from "vue-flickity";
export default {
  props: {
    roomInfo: {
      type: [Array, Object]
    }
  },
  components: {
    Flickity
  },
  data() {
    return {
      test: false,
      loadedImgs: 0,
      currentNavSlide: 0,
      sliderOptions: {
        prevNextButtons: false,
        pageDots: false
      },
      navSettings: {
        prevNextButtons: false,
        contain: true,
        pageDots: false
      }
    };
  },
  methods: {
    getSlideId(id) {
      this.$refs.navSlider.select(id - 1);
      this.$refs.mainSlider.select(id - 1);
    },
    close() {
        document.querySelector("html").style.overflow = ""
        this.$emit("close");
    },
    loaded() {
      this.$refs.mainSlider.resize();
      this.loadedImgs++;
    },
    next() {
      this.$refs.navSlider.next();
      this.$refs.mainSlider.select(this.currentNavSlide);
    },
    previous() {
      this.$refs.navSlider.previous();
      this.$refs.mainSlider.select(this.currentNavSlide);
    }
  },
  computed: {
    galleryData() {
      return this.roomInfo.constructor[0].content;
    },
    isImgsLoaded() {
      return Object.keys(this.galleryData.list).length <= this.loadedImgs;
    }
  },
  mounted() {
    this.$refs.navSlider.on("change", (idx) => {
      this.currentNavSlide = idx;
    });
    this.$refs.mainSlider.on("change", (idx) => {
      this.$refs.navSlider.select(idx);
    });
    const leftEl = document.querySelector(".roomInformation");
    // const leftWr = leftEl.querySelector(".text-wrapper");
    const rightEl = document.body;
    // const rightWr = rightEl.querySelector(".text-wrapper");

    leftEl.addEventListener("scroll", () => {
      //соотношение прокруток будет равно соотношению высот внутренних элементов
      //за вычетом высоты родителя
      //отсюда получаем формулу расчета прокрутки правого блока в зависимости от левого
      rightEl.scrollTop =
        (leftEl.scrollTop * rightEl.clientHeight) /leftEl.clientHeight;
    });
  }
};
</script>

<style lang="sass" scoped>
.overflow
    position: fixed
    background-color: rgba(0, 0, 0, 0.85)
    top: 0px
    left: 0px
    right: 0px
    bottom: 0px
    z-index: 10000
    display: flex
    justify-content: center
    align-items: flex-end
.w1
    width: 100%
    max-width: 717px
    position: relative
    &::before
        content: ''
        position: absolute
        bottom: 0
        left: 0
        right: 0
        height: 85px
        background-color: red
        z-index: 10
        background: linear-gradient(0deg, $c-gray 25%, rgba($c-gray,0) 100%)
.roomInformation
    background-color: $c-gray
    padding: 0px 38px 96px 38px
    color: #fff
    width: 100%
    position: relative
    max-height: calc(100vh - 106px * 2)
    overflow-y: scroll
    overflow-x: hidden
    z-index: 5
    .title
        font-style: normal
        font-weight: 500
        font-size: 25px
        line-height: 140%
        margin-bottom: 15px
    .desc
        font-weight: 300
        font-size: 17px
        line-height: 150%
    .roomOption
        margin-top: 42px
        display: grid
        grid-template-columns: repeat(2, 1fr)
        li
            font-weight: 600
            font-size: 16px
            line-height: 130%
            .liTitle
                display: flex
                align-items: flex-start
                margin-bottom: 20px
                align-items: center
            .icon
                margin-right: 14px
                font-size: 36px
                color: $c-btn
            .desc
                font-weight: 300
                font-size: 13px
                line-height: 130%
                color: #CACACA
.navSliderWrapper
    position: relative
    .sliderBtn
        position: absolute
        top: 50%
        transform: translateY(-50%)
        background-color: unset
        border: none
        i
            color: $c-btn
            font-size: 27px
    .prevBtn
        left: 0px
        transform: translateY(-50%) rotate(180deg)
        @media (max-width: 576px)
            left: -15px
    .nextBtn
        right: 0px
        @media (max-width: 576px)
            right: -15px
.roomAttr
    margin-bottom: 60px
.optionList
    li
        position: relative
        padding-left: 15px
        margin-bottom: 10px
        &::before
            content: ''
            position: absolute
            left: 0px
            top: 13px
            transform: translateY(-50%)
            width: 8px
            height: 1px
            background-color: $c-btn
        p
            font-weight: 300
            font-size: 17px
            line-height: 150%
            margin-bottom: 10px
        span
            font-weight: 300
            font-size: 13px
            line-height: 130%
            color: #CACACA
            display: block
</style>
<style lang="sass">
.hiddenOpacity
    opacity: 0
.previewNavSlider
    transition: 0.3s ease
    margin-top: 15px
    margin-bottom: 60px
    outline: none
    *
        outline: none
    img
        object-fit: cover
    .slide
        width: 69px
        height: 41px
        margin-right: 14px
.previewMainSlider
    transition: 0.3s ease
    outline: none
    *
        outline: none
    img
        height: 370px
        object-fit: cover
    .flickity-viewport
        overflow: visible
</style>
